import { FilterOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CheckboxProps, Table, TableColumnsType, TableProps } from 'antd';
import {
  ColumnFilterItem,
  FilterValue,
  RowSelectMethod,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import React, { Key, ReactElement } from 'react';
import { DEFAULT_PAGE_SIZE, PAGE_LIMITS } from '../../../../constants';
import './CustomTable.less';

interface Props {
  data: Record<string, string | boolean>[];
  colNames: string[];
  loading: boolean;
  tableName: string;
  filterCols?: Record<string, string[]>;
  sortKeys?: string[];
  pageSize: number;
  pageNumber?: number;
  totalCount?: number;
  pagination?: boolean;
  hasRowSelection?: boolean;
  onRowSelection?: (pageNumber: number, records: Record<string, string>[]) => void;
  selectedRows?: Record<string, string | boolean>[];
  defaultSelectedRows?: Record<string, string | boolean>[];
  checkboxConfig?: (record: Record<string, string>) => CheckboxProps;
  onChange: (
    paginationConfig: TablePaginationConfig,
    selectedFilters: Record<string, FilterValue | null>,
    action: 'paginate' | 'sort' | 'filter',
  ) => void;
  emptytDataContent?: ReactElement;
  tableConfig?: TableColumnsType<Record<string, string>>;
  onRowClick?: (record: Record<string, string>) => void;
  scrollProps?: {
    x: number | true | string;
    y: number | string;
  };
  uniqueKey?: string;
}

const CustomTable: React.FC<Props> = ({
  data,
  tableName,
  colNames,
  filterCols,
  sortKeys,
  pageSize,
  totalCount,
  onChange,
  loading,
  pagination,
  hasRowSelection,
  onRowSelection,
  pageNumber,
  emptytDataContent,
  selectedRows,
  defaultSelectedRows,
  checkboxConfig,
  tableConfig,
  onRowClick,
  scrollProps,
  uniqueKey,
}) => {
  const getFilters = (key: string): ColumnFilterItem[] => {
    return (
      filterCols?.[key]?.map((val: string) => ({
        text: val,
        value: val,
      })) || []
    );
  };

  const renderCellText = (key: string, text: string, record: Record<string, string>) => {
    // Highlighting Future Maturity Text if it is not same as Current Maturity
    if (
      tableName === 'process-maturity' &&
      ['Future Maturity', 'Current Maturity'].every((col) => colNames.includes(col)) &&
      (key === 'Future Maturity' || key === 'Current Maturity') &&
      record?.['Future Maturity'] !== record?.['Current Maturity']
    ) {
      return <span className="highlighted-cell-text">{text}</span>;
    } else if (
      tableName === 'gen-arch-table' &&
      key === 'Disposition' &&
      record?.['Disposition'] === 'Not To-be'
    ) {
      return <span className="differed-cell-text">{text}</span>;
    }
    return <span>{text}</span>;
  };

  // Getting the columns configuration dynamically
  const columns: TableColumnsType<Record<string, string>> =
    tableConfig ||
    colNames.map((key: string) => ({
      title: key.toLocaleUpperCase(),
      dataIndex: key,
      render: (text, record) => renderCellText(key, text, record),
      // Add filter if required
      ...(filterCols && Object.keys(filterCols).length && filterCols[key]
        ? {
            filters: getFilters(key),
            filterIcon: <FilterOutlined />,
          }
        : {}),
      // Add sorter if required
      ...(sortKeys && sortKeys.length && sortKeys.includes(key)
        ? {
            sorter: (a, b) => a?.[key]?.length - b?.[key]?.length,
          }
        : {}),
      // set width to minimal if column is indexing
      ...(key === 'S No.'
        ? {
            width: '6%',
          }
        : {}),
    }));

  const tableData = data.map((val: Record<string, string | boolean>, index: number) => ({
    key: `${tableName}-${val?.[uniqueKey || index]}`,
    ...(val || {}),
  }));

  const onTableConfigChange: TableProps<Record<string, string>>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    onChange(pagination, filters, extra.action);
  };

  const renderCount = (total: number, range: [min: number, max: number]): ReactElement => (
    <span>
      {range[0]}-{range[1]} of {totalCount || data?.length} items
    </span>
  );

  const onRowSelectionChange = (
    selectedRowsKeys: Key[],
    records: Record<string, string>[],
    info: { type: RowSelectMethod },
  ) => {
    onRowSelection && onRowSelection(pageNumber || -1, records);
  };

  return (
    <div className="table-container">
      <Table
        loading={loading}
        rowClassName={(_: Record<string, string>, index: number) =>
          index % 2 === 0 ? 'highlighted-row' : ''
        }
        columns={columns}
        dataSource={tableData}
        onChange={onTableConfigChange}
        onRow={(record) => ({
          onClick: onRowClick ? () => onRowClick(record) : undefined,
        })}
        rowSelection={
          hasRowSelection
            ? {
                type: 'checkbox',
                onChange: onRowSelectionChange,
                getCheckboxProps: checkboxConfig,
                selectedRowKeys: selectedRows?.map(
                  (record: Record<string, string | boolean>) =>
                    `${tableName}-${record?.[uniqueKey || '']}`,
                ),
                defaultSelectedRowKeys: defaultSelectedRows?.map(
                  (record: Record<string, string | boolean>) =>
                    `${tableName}-${record?.[uniqueKey || '']}`,
                ),
              }
            : undefined
        }
        pagination={
          pagination
            ? {
                pageSize: pageSize || DEFAULT_PAGE_SIZE,
                showTotal: renderCount,
                totalBoundaryShowSizeChanger: 1,
                pageSizeOptions: PAGE_LIMITS,
                showSizeChanger: true,
                className: 'paginator',
                hideOnSinglePage: true,
                total: totalCount,
                current: pageNumber,
                nextIcon: <RightOutlined />,
                prevIcon: <LeftOutlined />,
              }
            : false
        }
        locale={
          emptytDataContent && !loading
            ? {
                emptyText: emptytDataContent,
              }
            : undefined
        }
        scroll={scrollProps || undefined}
      />
    </div>
  );
};

export default CustomTable;
