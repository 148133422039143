import { ArrowUpOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu } from 'antd';
import { TSubSectionResponse } from 'libs/store/DmapPhase0State/LandingPageState';
import React, { ChangeEvent, useState } from 'react';
import ReusableModal from '../FormModal/ReusableModal';
import './ChatPrompt.less';

interface Props {
  promptText: string;
  selectedSection: string;
  selectedSubsection: string;
  disabled: boolean;
  onSubmit: (text: string) => void;
  subsections: TSubSectionResponse<any>[];
  onApplyAll: () => void;
}

const ChatPrompt: React.FC<Props> = ({
  promptText,
  selectedSection,
  selectedSubsection,
  disabled,
  onSubmit,
  subsections,
  onApplyAll,
}) => {
  const [text, setText] = useState<string>('');
  const [showApplyAllModal, setShowApplyAllModal] = useState<boolean>(false);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onSubmitClick = () => {
    onSubmit(text);
    setText('');
  };

  const getSubsectionsCount = (): number => {
    if (Array.isArray(subsections)) {
      return (
        subsections?.filter((item: TSubSectionResponse<any>) => item.subsection !== 'null')
          ?.length || 0
      );
    } else {
      return 1;
    }
  };

  const onModalCancel = () => {
    setShowApplyAllModal(false);
  };

  const onModalSubmit = () => {
    onApplyAll();
    setShowApplyAllModal(false);
  };

  const applyAllMenu = (
    <Menu>
      <Menu.Item disabled={disabled} key="1" onClick={() => setShowApplyAllModal(true)}>
        Apply to all sub-sections
      </Menu.Item>
    </Menu>
  );

  return selectedSubsection ? (
    <>
      <div className="input-wrapper">
        <Input
          placeholder="Enter prompt to regenerate content of this section..."
          value={text}
          disabled={disabled}
          onChange={onInputChange}
          onPressEnter={onSubmitClick}
          className="inputbox"
        />
        <Button
          onClick={onSubmitClick}
          disabled={disabled}
          icon={<ArrowUpOutlined />}
          className="arrow-button"
        />
      </div>
      {promptText && (
        <div className="prompt-wrapper">
          <div className="prompt-text">{promptText}</div>
          {getSubsectionsCount() > 1 && (
            <Dropdown overlay={applyAllMenu} placement="topRight">
              <Button className="more-btn" icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </div>
      )}
      {showApplyAllModal ? (
        <ReusableModal
          visible
          onCancel={onModalCancel}
          title="Are you Sure?"
          cancelButtonText="No, Cancel"
          submitButtonText="Yes, Apply"
          onSubmit={onModalSubmit}
        >
          <p>
            Are you sure you want to apply the following prompt to all other sub-sections under{' '}
            {selectedSection}?
          </p>

          <div className="apply-prompt">
            <p>{promptText}</p>
          </div>
        </ReusableModal>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default ChatPrompt;
