import { TUploadFilesPayload } from 'libs/models/Architecture.model';
import { TExistingFilesPayload } from 'libs/models/ReviewScreenModels';
import { TAllSectionDetailsPayload } from 'libs/store/DmapPhase0State/LandingPageState';

export const DEFAULT_PAGE_SIZE = 5;
export const PAGE_LIMITS = [5, 10, 15, 20];

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request`;

export const ROUTES = {
  // Landing Page
  GET_ALL_SECTION_DETAILS: (payload: TAllSectionDetailsPayload) =>
    `${BASE_URL}/sections/getAllSections?projectTypeId=${payload.projectTypeId}`,
  GET_PROJECT_TYPES: () => `${BASE_URL}/ProjectType`,

  // Review Screen
  GET_SECTIONS_BY_ID: (reqId: number) => `${BASE_URL}/sections/getAllSectionByRequestId/${reqId}`,
  GET_SECTION_DETAILS: (reqId: string, selectedItem: string, pageSize: number) =>
    `${BASE_URL}/previewBySection?requestId=${reqId}&section=${selectedItem}&pageSize=${pageSize}`,
  GET_SUBSECTION_DETAILS: (reqId: string) => `${BASE_URL}/previewBySubsection?requestId=${reqId}`,
  SAVE_EDITED_CONTENT: (reqId: number, selectedItem: string) =>
    `${BASE_URL}/data/update?request_id=${reqId}&section=${selectedItem}`,
  GET_DOWNLOAD_URL_BY_ID: (reqId: string) =>
    `${BASE_URL}/download-output-as-zip?requestId=${reqId}&filePath=/`,
  REGENERATE_HEADERS: () => `${BASE_URL}/modifyHeadersByUserInput`,
  SAVE_REGENERATED_HEADERS: (reqId: string) => `${BASE_URL}/saveModifiedHeaders/${reqId}`,
  GET_EXISTING_FILES_BY_ID: (payload: TExistingFilesPayload) =>
    `${BASE_URL}/getAllExistingFiles?request_id=${payload.reqId}&folderType=${payload.folderType}${
      payload.fileName ? '&fileName=' + payload.fileName : ''
    }`,

  // Shared routes
  UPLOAD_FILES: (requestId: number, filePrefix: string, operation: string, sections: string[]) =>
    `${BASE_URL}/process?request_id=${requestId}&operation=${operation}&prefixes=${filePrefix}&sections=${sections}`,

  //KDD
  GENERATE_KDD: () => `${BASE_URL}/generateKDDUsingUserPrompt`,
  SAVE_KDD: () => `${BASE_URL}/saveTable`,

  // Architecture
  UPLOAD_FILE: (payload: TUploadFilesPayload) =>
    `${BASE_URL}/uploadMultipleFiles?requestId=${payload.requestId}&prefixes=${payload.prefixes}&uploadType=${payload.uploadType}`,
  GENERATE_DIAGRAM: () => `${BASE_URL}/generateArchitectureDiagram`,

  // Chat
  GENERATE_PROMPT_RESULT: (suffix: string) => `${BASE_URL}/modificationByUserInput?${suffix}`,
};

export enum SECTION_CONSTANT_MAPPING {
  ARCHITECTURE = 'Architecture',
  WORKSHOP_ENABLER = 'Workshop Enablers',
  PROCESS_TRANSFORMATION = 'Process transformation',
  PROJECT_PLAN = 'Project Plan',
  WORKSHOP_ENABLER_AND_KDD = 'Workshop Enablers and KDDs',
  IMPLEMENTATION_PLAN = 'Implementation Plan',
  TRANSFORMATION_DEEPDIVE = 'Transformation Deepdive',
}

export const SUB_SECTION_MAPPING = {
  [SECTION_CONSTANT_MAPPING.ARCHITECTURE]: {
    AS_IS_ARCHITECTURE: 'As-Is Architecture',
    GENERATE_ARCHITECTURE_DIAGRAM: 'Generate Architecture Diagram',
  },
  [SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER]: {
    QUESTIONNAIRE: 'Workshop Questionnaire',
    PAIN_POINTS: 'Pain Points',
    HANA_CHANGES: 'S4 HANA Changes',
  },
  [SECTION_CONSTANT_MAPPING.PROCESS_TRANSFORMATION]: {
    IN_SCOPE_PROCESSES: 'In Scope Processes',
    MATURITY_ASSESSMENT: 'Maturity Assessment',
  },
  [SECTION_CONSTANT_MAPPING.PROJECT_PLAN]: {
    ESTIMATOR: 'Estimator',
    ESTIMATES: 'Project Plan Estimates',
  },
  [SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER_AND_KDD]: {
    OVERVIEW: 'KDD Overview',
  },
};

export const SUBSECTIONS_WITH_TABLE_SELECTION = [
  SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER_AND_KDD].OVERVIEW,
];

export const SUBSECTIONS_WITH_SEARCH = [
  ...SUBSECTIONS_WITH_TABLE_SELECTION,
  SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.PROCESS_TRANSFORMATION].IN_SCOPE_PROCESSES,
  SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.PROCESS_TRANSFORMATION].MATURITY_ASSESSMENT,
];
