import React, { ReactNode, useEffect, useState } from 'react';
import { TDropdownData, TFormField } from '../../libs/models/NewRqst.model';
import { Button, Form, FormInstance, Input, Select, Tooltip, Upload, message } from 'antd';
import {
  CloseOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import './DynamicForm.less';
import CustomCheckboxGroup from './components/CheckboxGroup/CheckboxGroup';
import TextArea from 'antd/lib/input/TextArea';
import { formatBytes, getPlaceholderForFile } from '../../utils/DynamicForm.util';

interface Props {
  form: FormInstance;
  fieldsConfig: TFormField[];
  options?: Record<string, any>; // for select type fields
  initialValues?: Record<string, any>;
  selectedValues?: Record<string, any>;
  setSelectedValues?: (values: Record<string, any>) => any;
  defaultActiveKey?: string | number | string[] | number[];
  dynamicFieldCount?: number;
  getDynamicField?: (filedNo: number) => TFormField[];
  updateDynamicCount?: (count: number) => void;
  submit?: () => void;
}

const DynamicForm = ({
  form,
  fieldsConfig,
  options,
  initialValues,
  selectedValues,
  setSelectedValues,
  defaultActiveKey,
  dynamicFieldCount,
  getDynamicField,
  updateDynamicCount,
  submit,
}: Props) => {
  const [dynamicFields, setDynamicFields] = useState<TFormField[]>(
    getDynamicField ? getDynamicField(1) : [],
  );
  const [showUploadList, setShowUploadList] = useState<Record<string, boolean>>({});
  const [, setRefreshForm] = useState(false);
  const { Option } = Select;
  Form.useWatch(
    fieldsConfig
      ?.filter((field: TFormField) => field.type === 'upload')
      ?.map((field: TFormField) => field.name),
    form,
  );

  useEffect(() => {
    setDynamicFields((_) => (getDynamicField ? getDynamicField(1) : []));
  }, [getDynamicField]);

  /**
   * Method to add one more dynamic field in UI by incrementing the count
   */
  const incrementDynamicFieldCount = () => {
    setDynamicFields((prevDynamicFields: TFormField[]) => [
      ...prevDynamicFields,
      ...(getDynamicField ? getDynamicField((dynamicFieldCount ?? -1) + 1) : []),
    ]);
    updateDynamicCount && updateDynamicCount((dynamicFieldCount ?? -1) + 1);
  };

  /**
   * Remove Upload button if not needed
   * @param event
   * @param name
   */
  const removeUploadBtnByIndex = (event: any, fieldName: string) => {
    event.preventDefault();
    event.stopPropagation();
    const fieldPrefix = fieldName.split('-').slice(0, -1).join('-');
    const indexFromName = parseInt(
      fieldName.split('-')?.[fieldName.split('-')?.length - 1] ?? '-1',
    );

    new Array(dynamicFieldCount).fill(1).map((_: number, index: number) => {
      if (dynamicFieldCount === index + 1) {
        form.resetFields([[fieldPrefix, index + 1].join('-')]);

        const newFields: TFormField[][] = new Array(dynamicFieldCount - 1)
          .fill(1)
          .map((_: number, index: number) => (getDynamicField ? getDynamicField(index + 1) : []));

        setDynamicFields((_) => newFields.flat(1));
        updateDynamicCount && updateDynamicCount(dynamicFieldCount - 1);
      }
      if (index + 1 >= indexFromName) {
        form.setFieldValue(
          [fieldPrefix, index + 1].join('-'),
          form.getFieldValue([fieldPrefix, index + 2].join('-')),
        );
      }
    });
  };

  const deleteFile = (field: TFormField) => {
    form.setFieldValue(field.name, undefined);
    setRefreshForm((prevRefreshForm) => !prevRefreshForm);
  };

  const renderUploadIcon = (
    field: TFormField,
    form: FormInstance,
    renderData: string | ReactNode,
  ) => (
    <legend>
      <Upload
        accept={field.accept}
        maxCount={1}
        showUploadList={false}
        customRequest={({ onSuccess }: any) => {
          setTimeout(() => {
            if (onSuccess) {
              onSuccess('ok');
            }
          }, 500);
        }}
        fileList={form.getFieldValue(field.name)}
        onChange={(info: UploadChangeParam<UploadFile>) => {
          if (
            form.getFieldValue(field.name)?.[0]?.name &&
            info.file.name !== form.getFieldValue(field.name)?.[0]?.name &&
            field.forUpdateValue
          ) {
            message.error('Make sure the existing and new file name matches !!!');
            return;
          }
          setShowUploadList((prevUploadList: Record<string, boolean>) => ({
            ...prevUploadList,
            [field.name]: true,
          }));
          if (info.file.status === 'done') {
            setShowUploadList((prevUploadList: Record<string, boolean>) => ({
              ...prevUploadList,
              [field.name]: false,
            }));
            form.setFieldValue(field.name, [info.file]);
          } else if (info.file.status === 'removed') {
            form.resetFields([field.name]);
          } else {
            form.setFieldValue(field.name, [info.file]);
          }
        }}
      >
        <Tooltip title={'Update the document'}>
          {typeof renderData === 'string' ? (
            <span className="update-btn-text">{renderData}</span>
          ) : (
            renderData
          )}
        </Tooltip>
      </Upload>
    </legend>
  );

  /**
   * Method to render each field
   * @param field
   * @param form
   * @returns
   */
  const renderField = (field: TFormField, form: FormInstance, disabled: boolean): ReactNode => {
    switch (field.type) {
      case 'text':
        return <Input placeholder={field.placeholderText} />;
      case 'textarea':
        return <TextArea rows={4} placeholder={field.placeholderText} />;
      case 'checkbox-group':
        return (
          <CustomCheckboxGroup
            field={field}
            values={options?.[field.name]}
            checkboxCheckedItems={selectedValues}
            setCheckboxCheckedItems={setSelectedValues}
            defaultActiveKey={defaultActiveKey}
          />
        );
      case 'select':
        return (
          <Select
            mode={field.mode}
            placeholder={field.placeholderText}
            showArrow
            disabled={disabled}
          >
            {options?.[field.name]?.map((data: TDropdownData) => (
              <Option key={data.value} value={data.value}>
                {data.label}
              </Option>
            ))}
          </Select>
        );
      case 'upload':
        return (
          <Upload
            accept={field.accept}
            maxCount={1}
            customRequest={({ onSuccess }: any) => {
              setTimeout(() => {
                if (onSuccess) {
                  onSuccess('ok');
                }
              }, 500);
            }}
            disabled={form.getFieldValue(field.name)?.[0]?.name ? true : false}
            fileList={form.getFieldValue(field.name)}
            showUploadList={showUploadList?.[field.name] || false}
            onChange={(info: UploadChangeParam<UploadFile>) => {
              setShowUploadList((prevUploadList: Record<string, boolean>) => ({
                ...prevUploadList,
                [field.name]: true,
              }));
              if (info.file.status === 'done') {
                setShowUploadList((prevUploadList: Record<string, boolean>) => ({
                  ...prevUploadList,
                  [field.name]: false,
                }));
                form.setFieldValue(field.name, [info.file]);
              } else if (info.file.status === 'removed') {
                form.resetFields([field.name]);
              } else {
                form.setFieldValue(field.name, [info.file]);
              }
            }}
          >
            <Button className="upload-btn-container">
              <div className="upload-btn-txt">
                <span className="btn-title">
                  {!form.getFieldValue(field.name)?.[0]?.name && (
                    <UploadOutlined className="upload-icon" />
                  )}
                  <b
                    className={`${
                      form.getFieldValue(field.name)?.[0]?.name ? 'btn-title-black' : ''
                    }`}
                  >
                    {getPlaceholderForFile(
                      form.getFieldValue(field.name)?.[0],
                      field.placeholderText || '',
                    )}
                    {form.getFieldValue(field.name)?.[0]?.name && (
                      <span className="file-size">
                        | {formatBytes(form.getFieldValue(field.name)?.[0]?.size || 0)}
                      </span>
                    )}
                  </b>
                </span>
                <div className="icons">
                  {!field.forUpdateValue ? (
                    <>
                      {form.getFieldValue(field.name) && (
                        <>
                          <DeleteOutlined onClick={() => deleteFile(field)} className="i-icon" />
                          {renderUploadIcon(
                            field,
                            form,
                            field.forUpdateValue ? 'Update' : <UploadOutlined />,
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    renderUploadIcon(
                      field,
                      form,
                      field.forUpdateValue ? 'Update' : <UploadOutlined />,
                    )
                  )}
                  {field.canRemove && (
                    <CloseOutlined
                      className="i-close"
                      onClick={(event: any) => removeUploadBtnByIndex(event, field.name)}
                    />
                  )}
                </div>
              </div>
            </Button>
          </Upload>
        );
      case 'dynamic-field-incr-btn':
        return (
          <div className="ant-upload ant-upload-select ant-upload-select-text">
            <span className="ant-upload">
              <Button className="upload-btn-container" onClick={incrementDynamicFieldCount}>
                <div className="upload-btn-txt">
                  <span className="btn-title">
                    <UploadOutlined className="upload-icon" />
                    <b>{field.placeholderText}</b>
                  </span>
                </div>
              </Button>
            </span>
          </div>
        );
      default:
        break;
    }
  };

  const isDepedantFieldValid = (
    field: TFormField,
    value: string | object | number | Array<any>,
  ) => {
    if (field.dependencies?.length && field.canBeDisabled) {
      console.warn(value, typeof value);
      switch (typeof value) {
        case 'string':
          return value.length > 0;
        case 'object':
          return Object.keys(value)?.length > 0;
        case 'number':
          return value >= 0;
        case 'undefined':
          return false;
        case 'boolean':
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  /**
   * Method to iterate over each fields config and pass it to renderField method to render it
   * @param fields
   * @param form
   * @returns
   */
  const renderFields = (fields: TFormField[], form: FormInstance) => {
    return fields.map((field: TFormField) => (
      <Form.Item dependencies={field.dependencies || []}>
        {({ getFieldValue }) => (
          <Form.Item
            key={field.name}
            label={field.label || null}
            className={field.classes || ''}
            name={field.name}
            rules={field.rules || []}
          >
            {renderField(
              field,
              form,
              !isDepedantFieldValid(field, getFieldValue(field.dependencies?.[0])),
            )}
          </Form.Item>
        )}
      </Form.Item>
    ));
  };

  return (
    <Form
      requiredMark={false}
      initialValues={initialValues || {}}
      className="dynamic-form-modal"
      form={form}
      layout="vertical"
      onFinish={submit ? submit : undefined}
    >
      {renderFields([...dynamicFields, ...fieldsConfig], form)}
    </Form>
  );
};

export default DynamicForm;
