import { Tabs } from 'antd';
import ChatPrompt from 'Components/ChatPrompt/ChatPrompt';
import RichTextEditor from 'Components/RichTextEditor/RichTextEditor';
import { TPromptResultPayload, TSubsectionPromptResult } from 'libs/models/ChatPrompt.model';
import { TSectionDetails } from 'libs/models/landingPageModels';
import {
  TProcessSimpDetailsPayload,
  TProcessSimplificationState,
} from 'libs/models/ProcessSimplification.model';
import {
  applyChatToAllRequest,
  fetchPromptResult,
  resetApplyChatToAll,
  resetPromptResult,
} from 'libs/store/DmapPhase0Actions/ChatPrompt.actions';
import { updateSaveValue } from 'libs/store/DmapPhase0Actions/LandingPageActions';
import {
  fetchProcessSimplificationDetails,
  resetProcessSimpDetails,
  setProcessSimpEditedData,
} from 'libs/store/DmapPhase0Actions/ProcessSimplification.actions';
import {
  TDataState,
  TRootState,
  TSubSectionResponse,
} from 'libs/store/DmapPhase0State/LandingPageState';
import { DeltaStatic, Sources } from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import {
  getLoadingText,
  getPayloadForGenerateAndApplyPrompt,
} from 'utils/ProcessSimplification.utils';
import './ProcessSimplification.less';

type ProcessSimplificationProps = {
  selectedItem: string;
  onSelectedMenuItem: string;
  sectionDetails: TSectionDetails | null | undefined;
  startPolling: () => void;
  updateSideNav: () => void;
  details: TProcessSimplificationState;
  promptResultDetails: TDataState<TSubsectionPromptResult> | null;
  applyChatToAllDetails: TDataState<any> | null;
  saveEditsDetails: TDataState<any>;
  updateSaveEnabled: (enabled: boolean) => void;
  getProcessSimpDetails: (params: {
    reqId: string;
    selectedItem: string;
    pageSize: number;
  }) => void;
  resetSubsectionDetails: () => void;
  generatePromptResult: (payload: any) => void;
  resetPromptResults: () => void;
  applyChatToAllSubsections: (payload: any) => void;
  resetApplyToAllResults: () => void;
  setEditedData: (data: any) => void;
};

const ProcessSimplification: React.FC<ProcessSimplificationProps> = ({
  selectedItem,
  onSelectedMenuItem,
  sectionDetails,
  startPolling,
  updateSideNav,

  details,
  promptResultDetails,
  applyChatToAllDetails,
  saveEditsDetails,

  updateSaveEnabled,
  getProcessSimpDetails,
  resetSubsectionDetails,
  generatePromptResult,
  resetPromptResults,
  applyChatToAllSubsections,
  resetApplyToAllResults,
  setEditedData,
}) => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState<string>('');
  const [selectedSubsectionTab, setSelectedSubsectionTab] = useState<string>('');
  const textAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getProcessSimpDetails &&
      getProcessSimpDetails({
        reqId: id,
        selectedItem,
        pageSize: 0,
      });
  }, []);

  useEffect(() => {
    setSelectedSubsectionTab(
      details?.existingData?.data?.find(({ processArea }) => processArea === onSelectedMenuItem)
        ?.subsection || '',
    );
  }, [onSelectedMenuItem, details?.existingData?.data?.length]);

  useEffect(() => {
    if (textAreaRef.current) textAreaRef.current.scrollTop = 0;
  }, [onSelectedMenuItem, selectedSubsectionTab]);

  useEffect(() => {
    if (Object.values(details?.editedData || {}).length) {
      updateSaveEnabled && updateSaveEnabled(true);
    } else {
      updateSaveEnabled && updateSaveEnabled(false);
    }
  }, [details]);

  useEffect(() => {
    if (promptResultDetails?.loading === false) {
      if (promptResultDetails.data) {
        setEditedData && setEditedData(promptResultDetails.data);
        resetPromptResults && resetPromptResults();
      }
    }
  }, [promptResultDetails]);

  useEffect(() => {
    if (applyChatToAllDetails?.loading === false) {
      if (applyChatToAllDetails.data) {
        setTimeout(() => {
          resetSubsectionDetails && resetSubsectionDetails();
          updateSideNav();
          startPolling();
          setPrompt('');
        }, 2000);
      }
      resetApplyToAllResults && resetApplyToAllResults();
    }
  }, [applyChatToAllDetails]);

  const isChatDisabled = (): boolean => {
    return Boolean(
      details?.existingData?.loading ||
        promptResultDetails?.loading ||
        applyChatToAllDetails?.loading ||
        saveEditsDetails?.loading,
    );
  };

  useEffect(() => {
    if (isChatDisabled()) {
      if (textAreaRef.current) {
        textAreaRef.current.scrollTop = 0;
      }
    }
  }, [isChatDisabled()]);

  const getEditorText = (): string => {
    return (
      details?.editedData?.[selectedSubsectionTab] ||
      details?.existingData?.data?.find(
        (data: TSubSectionResponse<string>) => data.subsection === selectedSubsectionTab,
      )?.data?.response ||
      ''
    );
  };

  const handleRTEChange = (content: string, delta: DeltaStatic, source: Sources) => {
    if (source === 'user') {
      const existingText =
        details?.existingData?.data?.find(
          (data: TSubSectionResponse<string>) => data.subsection === selectedSubsectionTab,
        )?.data?.response || '';
      let trimmed = content.replace(/\n|\r/g, '');
      if (existingText !== trimmed) {
        setEditedData &&
          setEditedData({
            processArea: onSelectedMenuItem,
            subsection: selectedSubsectionTab,
            data: trimmed,
          });
      } else {
        setEditedData &&
          setEditedData({
            processArea: onSelectedMenuItem,
            subsection: selectedSubsectionTab,
            data: '',
          });
      }
    }
  };

  const handleEnterPress = async (promptText: string) => {
    setPrompt(promptText);
    dispatch(updateSaveValue(selectedItem, true));
    const subsectionsData = [
      {
        processArea: onSelectedMenuItem,
        subsection: selectedSubsectionTab,
        data: getEditorText(),
      },
    ];
    const payload = getPayloadForGenerateAndApplyPrompt(
      id,
      selectedItem,
      promptText,
      '',
      '',
      subsectionsData,
    );

    generatePromptResult && generatePromptResult(payload);
  };

  const handleApplytoAll = async () => {
    try {
      if (details?.existingData) {
        const subsections =
          details?.existingData?.data
            ?.filter((item: any) => item.subsection !== selectedSubsectionTab)
            .map((item: any) => ({
              processArea: item.processArea,
              subsection: item.subsection,
              data: item.data.response,
            })) || [];

        const payload = getPayloadForGenerateAndApplyPrompt(
          id,
          selectedItem,
          prompt,
          selectedSubsectionTab,
          getEditorText(),
          subsections,
        );
        console.warn(payload);
        applyChatToAllSubsections && applyChatToAllSubsections(payload);
      }
    } catch (error) {
      console.warn('Error', error);
    }
  };

  return (
    <div className="process-simplification-container">
      {onSelectedMenuItem && (
        <Tabs
          className="subsection-tabs"
          activeKey={selectedSubsectionTab}
          onChange={setSelectedSubsectionTab}
          items={
            details?.existingData?.data
              ?.filter(({ processArea }) => processArea === onSelectedMenuItem)
              .map(({ subsection }) => ({ key: subsection, label: subsection })) || []
          }
        />
      )}
      <div
        ref={textAreaRef}
        className={`text-editor-container ${isChatDisabled() ? 'text-editor-disabled' : ''}`}
      >
        <RichTextEditor
          editable={true}
          editorText={getEditorText()}
          loading={
            isChatDisabled()
              ? getLoadingText(
                  details?.existingData?.loading,
                  promptResultDetails?.loading,
                  saveEditsDetails?.loading,
                  applyChatToAllDetails?.loading,
                )
              : undefined
          }
          selectedSubsection={selectedSubsectionTab}
          handleChange={handleRTEChange}
        />
      </div>
      <ChatPrompt
        disabled={isChatDisabled()}
        selectedSection={sectionDetails?.section || selectedItem}
        selectedSubsection={selectedSubsectionTab}
        subsections={details?.existingData?.data || []}
        promptText={prompt}
        onSubmit={handleEnterPress}
        onApplyAll={handleApplytoAll}
      />
    </div>
  );
};

const mapStateToProps = (state: TRootState, { selectedItem }: ProcessSimplificationProps) => ({
  details: state.processSimplification,
  promptResultDetails: state.chatPrompt.generateResult,
  applyChatToAllDetails: state.chatPrompt.applyChatToAll,
  saveEditsDetails: state.editedContentToSave,
  sectionDetails: state.sectionsById.data?.find(
    (section: TSectionDetails) => section.sectionConstant === selectedItem,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch, { selectedItem }: ProcessSimplificationProps) => ({
  updateSaveEnabled: (enabled: boolean) => dispatch(updateSaveValue(selectedItem, enabled)),
  getProcessSimpDetails: (payload: TProcessSimpDetailsPayload) =>
    dispatch(fetchProcessSimplificationDetails(payload)),
  resetSubsectionDetails: () => dispatch(resetProcessSimpDetails()),
  generatePromptResult: (payload: TPromptResultPayload) => dispatch(fetchPromptResult(payload)),
  resetPromptResults: () => dispatch(resetPromptResult()),
  applyChatToAllSubsections: (payload: TPromptResultPayload) =>
    dispatch(applyChatToAllRequest(payload)),
  resetApplyToAllResults: () => dispatch(resetApplyChatToAll()),
  setEditedData: (payload: TSubsectionPromptResult) => dispatch(setProcessSimpEditedData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessSimplification);
