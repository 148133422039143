import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import DynamicForm from 'Components/DynamicForm/DynamicForm';
import ReusableModal from 'Components/FormModal/ReusableModal';
import { TSectionDetails } from 'libs/models/landingPageModels';
import { TFetchFileData } from 'libs/models/ReviewScreenModels';
import { generateSlideContentRequest } from 'libs/store/DmapPhase0Actions/LandingPageActions';
import {
  fetchExistingFiles,
  fetchSectionsById,
} from 'libs/store/DmapPhase0Actions/ReviewScreen.actions';
import {
  ProcessRequestGenerateSlideContentRequestAction,
  TDataState,
  TRootState,
} from 'libs/store/DmapPhase0State/LandingPageState';
import { TFetchFiles, TSectionsByIdRequest } from 'libs/store/DmapPhase0State/ReviewScreen.state';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import {
  getFieldNameForUpdateFile,
  getFieldsConfigForUpdateFiles,
  getPayloadForFileUpdate,
} from 'utils/ReviewScreen.util';
import './ReviewModals.less';

interface Props {
  visible: boolean;
  onCancel: () => void;
  requestId: string;
  section: string;
  subSection: string;

  sectionDropdownData?: TSectionDetails[] | null;
  existingFiles?: TDataState<TFetchFileData[]> | undefined;

  fetchSections?: (reqId: number) => TSectionsByIdRequest;
  fetchFiles?: (reqId: string) => TFetchFiles;
  updateFiles?: (
    requestId: number,
    filePrefixes: string,
    operation: string,
    files: File[],
    sections: string[],
  ) => ProcessRequestGenerateSlideContentRequestAction;
}

const FileUpload: React.FC<Props> = ({
  visible,
  onCancel,
  requestId,
  sectionDropdownData,
  existingFiles,
  fetchSections,
  fetchFiles,
  updateFiles,
}) => {
  const [form] = Form.useForm();
  const { id }: { id: string } = useParams();

  useEffect(() => {
    if (visible) {
      fetchFiles && fetchFiles(id);
      fetchSections && fetchSections(parseInt(id));
    }
  }, [visible]);

  useEffect(() => {
    if (form && existingFiles?.data?.length) {
      existingFiles?.data?.map((data: TFetchFileData, index: number) => {
        form.setFieldValue(getFieldNameForUpdateFile(data.filetype, index), [
          {
            name: data.filename,
            size: data.fileSize,
          } as File,
        ]);
      });
    }
  }, [existingFiles?.data]);

  const handleModalSubmit = async (values: any) => {
    try {
      form.validateFields().then((_: FormInstance) => {
        const sections = form.getFieldValue('sections');
        const payloadData = getPayloadForFileUpdate(
          getFieldsConfigForUpdateFiles({ requestID: id, files: existingFiles?.data || [] }),
          form,
        );
        if (payloadData.files?.length) {
          updateFiles &&
            updateFiles(
              parseInt(requestId),
              payloadData.prefixes?.join(','),
              'update',
              payloadData.files,
              sections,
            );
        }
        form.resetFields();
        onCancel();
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <ReusableModal
      visible={visible}
      onCancel={onCancel}
      title="Input Files"
      cancelButtonText="Cancel"
      submitButtonText="Generate"
      onSubmit={handleModalSubmit}
    >
      <DynamicForm
        fieldsConfig={getFieldsConfigForUpdateFiles({
          requestID: id,
          files: existingFiles?.data || [],
        })}
        form={form}
        options={{
          'sections': sectionDropdownData?.map((section: TSectionDetails) => ({
            label: section.section,
            value: section.sectionConstant,
          })),
        }}
      />
    </ReusableModal>
  );
};

const mapStateToProps = (state: TRootState) => ({
  sectionDropdownData: state.allSections.data,
  existingFiles: state.reviewScreen?.data?.existingFiles,
});

const mapDispatchToProps = (dispatch: Dispatch, { subSection }: Props) => ({
  fetchSections: (requestId: number) => dispatch(fetchSectionsById(requestId)),
  fetchFiles: (reqId: string) =>
    dispatch(fetchExistingFiles({ reqId, subSection, folderType: '/raw_input/' })),
  updateFiles: (
    requestId: number,
    filePrefixes: string,
    operation: string,
    files: File[],
    sections: string[],
  ) => dispatch(generateSlideContentRequest(requestId, filePrefixes, operation, files, sections)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
