import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Image, Input, Layout } from 'antd';
import CheckIcon from 'assets/Images/Checklist.svg';
import FileUpload from 'container/ReviewScreen/FileUpload';
import useDebounce from 'libs/hooks/debounce';
import { TKDDSavePayload } from 'libs/models/KDD.model';
import { TProcessSimpDetailsPayload } from 'libs/models/ProcessSimplification.model';
import { TProjectTimeline, TSaveProjectTimelinePayload } from 'libs/models/ProjectPlan.model';
import { SaveApiPayload } from 'libs/models/ReviewScreenModels';
import { fetchKDDSubSection, saveKDDRequest } from 'libs/store/DmapPhase0Actions/KDD.actions';
import {
  fetchDownloadRequest,
  putEditedContentToSaveRequest,
  setBooleanVariable,
  updateSaveValue,
} from 'libs/store/DmapPhase0Actions/LandingPageActions';
import { fetchProcessMaturitySubSection } from 'libs/store/DmapPhase0Actions/ProcessMaturity.actions';
import { fetchProcessSimplificationDetails } from 'libs/store/DmapPhase0Actions/ProcessSimplification.actions';
import { saveTimelineReport } from 'libs/store/DmapPhase0Actions/ProjectPlan.actions';
import { TKDDSubSectionRequest, TSaveKDDRequest } from 'libs/store/DmapPhase0State/KDD.state';
import {
  DataState,
  PutEditedContentToSaveRequestAction,
  TDataState,
  TFetchSubSectionPayload,
  TRootState,
  TSaveFlagAction,
  TSubSectionResponse,
} from 'libs/store/DmapPhase0State/LandingPageState';
import { TProcessMaturitySubSectionRequest } from 'libs/store/DmapPhase0State/ProcessMaturity.state';
import { TProcessSimpDetailsRequest } from 'libs/store/DmapPhase0State/ProcessSimplification.state';
import { TSaveProjectTimelinesRequest } from 'libs/store/DmapPhase0State/ProjectPlan.state';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getPayloadForFetchingSubSection, getPayloadForSaveKDD } from 'utils/KDD.util';
import { getPayloadForSaveReport } from 'utils/projectPlan.util';
import {
  DEFAULT_PAGE_SIZE,
  SECTION_CONSTANT_MAPPING,
  SUB_SECTION_MAPPING,
  SUBSECTIONS_WITH_SEARCH,
  SUBSECTIONS_WITH_TABLE_SELECTION,
} from '../../constants';
import './Editor.less';

const { Header, Content } = Layout;

interface EditorProps {
  selectedItem: any;
  onSelectedMenuItem: any;
  children: any;
  searchText: string;
  setSearchText: (searchStr: string) => void;
  tableSelectedRows?: Record<number, Record<string, string>[]>;
  setTableSelectedRows: (data: Record<number, Record<string, string>[]>) => void;

  downloadDetails?: DataState;
  saveBtnFlags?: Record<string, boolean>;
  isFileDownloaded?: boolean;
  processSimplicationData?: TSubSectionResponse<string>[] | null;
  processSimplEditedData?: Record<string, string> | null;
  timelineSelectedValues?: Record<string, Record<string, string>>;
  timelineData?: TDataState<TProjectTimeline[]> | null;

  setIsDownloaded?: (flag: boolean) => any;
  fetchSubsectionForKDD?: (payload: TFetchSubSectionPayload) => TKDDSubSectionRequest;
  fetchSubsectionForMaturity?: (
    payload: TFetchSubSectionPayload,
  ) => TProcessMaturitySubSectionRequest;
  fetchDataForDownloadingFile?: (id: string) => any;
  updateSaveBtnFlag: (section: string, flag: boolean) => TSaveFlagAction;
  saveProjectTimeline?: (payload: TSaveProjectTimelinePayload) => TSaveProjectTimelinesRequest;
  saveEditedContent?: (
    reqId: number,
    selectedItem: string,
    payload: SaveApiPayload[],
  ) => PutEditedContentToSaveRequestAction;
  getProcessSimpDetails?: (payload: TProcessSimpDetailsPayload) => TProcessSimpDetailsRequest;
  saveKDDData?: (payload: TKDDSavePayload) => TSaveKDDRequest;
}

const Editor: React.FC<EditorProps> = ({
  selectedItem,
  onSelectedMenuItem,
  children,
  tableSelectedRows,
  searchText,
  setSearchText,
  setTableSelectedRows,

  downloadDetails,
  saveBtnFlags,
  isFileDownloaded,
  processSimplicationData,
  processSimplEditedData,
  timelineSelectedValues,
  timelineData,

  setIsDownloaded,
  fetchSubsectionForKDD,
  fetchSubsectionForMaturity,
  fetchDataForDownloadingFile,
  updateSaveBtnFlag,
  saveProjectTimeline,
  saveEditedContent,
  getProcessSimpDetails,
  saveKDDData,
}) => {
  const { id }: { id: string } = useParams();
  const [inputFileOpen, setInputFileOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    setSearchText('');
  }, [onSelectedMenuItem]);

  useEffect(() => {
    if (!isFileDownloaded && downloadDetails?.data !== null) {
      window.location.href = downloadDetails?.data.url;
      setIsDownloaded && setIsDownloaded(true);
    }
  }, [downloadDetails?.data, isFileDownloaded]);

  useDebounce(
    () => {
      if (!firstLoad && SUBSECTIONS_WITH_SEARCH.includes(onSelectedMenuItem)) {
        switch (onSelectedMenuItem) {
          case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.WORKSHOP_ENABLER_AND_KDD].OVERVIEW:
            setTableSelectedRows([]);
            fetchSubsectionForKDD &&
              fetchSubsectionForKDD(
                getPayloadForFetchingSubSection(
                  id,
                  1,
                  DEFAULT_PAGE_SIZE,
                  selectedItem,
                  onSelectedMenuItem,
                  searchText,
                  {},
                ),
              );
            break;
          case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.PROCESS_TRANSFORMATION]
            .IN_SCOPE_PROCESSES:
          case SUB_SECTION_MAPPING[SECTION_CONSTANT_MAPPING.PROCESS_TRANSFORMATION]
            .MATURITY_ASSESSMENT:
            fetchSubsectionForMaturity &&
              fetchSubsectionForMaturity(
                getPayloadForFetchingSubSection(
                  id,
                  1,
                  DEFAULT_PAGE_SIZE,
                  selectedItem,
                  onSelectedMenuItem,
                  searchText,
                  {},
                ),
              );
            break;
          default:
            break;
        }
      }
    },
    [searchText],
    500,
  );

  const handleDownload = async () => {
    try {
      fetchDataForDownloadingFile && fetchDataForDownloadingFile(id);
      setIsDownloaded && setIsDownloaded(false);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const toggleFileModal = (open: boolean) => {
    setInputFileOpen(open);
  };

  const handleSave = async () => {
    try {
      let sectionName = '';
      //for the other format of data
      if (
        processSimplicationData &&
        Array.isArray(processSimplicationData) &&
        selectedItem === SECTION_CONSTANT_MAPPING.TRANSFORMATION_DEEPDIVE
      ) {
        sectionName = selectedItem;
        const payload = processSimplicationData
          .filter(
            (detail: TSubSectionResponse<string>) =>
              detail.subsection && processSimplEditedData?.[detail.subsection],
          )
          .map((detail: TSubSectionResponse<string>) => ({
            subsection: detail.subsection,
            editedContent: processSimplEditedData?.[detail.subsection]
              ? processSimplEditedData?.[detail.subsection]
              : detail.data.response,
          }));
        if (payload) {
          saveEditedContent && saveEditedContent(parseInt(id), sectionName, payload);
          updateSaveBtnFlag && updateSaveBtnFlag(selectedItem, false);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          getProcessSimpDetails &&
            getProcessSimpDetails({
              reqId: id,
              selectedItem,
              pageSize: 0,
            });
        }
      } else if (selectedItem === 'Project Plan') {
        if (Object.keys(timelineSelectedValues || {})?.length) {
          const payload: TSaveProjectTimelinePayload = getPayloadForSaveReport(
            id,
            timelineData?.data || [],
            timelineSelectedValues || {},
          );
          saveProjectTimeline && saveProjectTimeline(payload);
        }
      } else if (
        selectedItem === 'Workshop Enablers and KDDs' &&
        onSelectedMenuItem === 'KDD Overview'
      ) {
        const payload = getPayloadForSaveKDD(
          selectedItem,
          onSelectedMenuItem,
          parseInt(id),
          tableSelectedRows || {},
        );
        saveKDDData && saveKDDData(payload);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const renderTableSelection = () => {
    const selectedRowsLength = Object.values(tableSelectedRows || {})?.flat(1)?.length || 0;

    return SUBSECTIONS_WITH_SEARCH.includes(onSelectedMenuItem) ? (
      <>
        {SUBSECTIONS_WITH_TABLE_SELECTION.includes(onSelectedMenuItem) ? (
          <div className={`selected-items ${selectedRowsLength ? 'items-count' : ''}`}>
            <Image src={CheckIcon} className="checklist-icon" alt="checklist" preview={false} />
            {selectedRowsLength ? (
              <span className="count-text">{selectedRowsLength} selected</span>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={searchText}
          className="button search-btn"
          onChange={onSearchChange}
        />
      </>
    ) : (
      <></>
    );
  };

  return (
    <Layout
      className="right-side-panel"
      style={{ height: 'calc(100vh - 7rem)', borderRadius: '0.4rem 0.4rem 0 0' }}
    >
      <Header className="header">
        {onSelectedMenuItem?.length > 0 ? (
          <div>
            <h4 className="title">{onSelectedMenuItem}</h4>
          </div>
        ) : (
          <div>
            <h4 className="title">Request Name</h4>
          </div>
        )}

        <div className="buttons">
          {renderTableSelection()}
          <Button
            onClick={handleDownload}
            icon={<DownloadOutlined />}
            className="button download-icon"
          />
          <Button onClick={() => toggleFileModal(true)} className="button">
            Input Files
          </Button>
          <FileUpload
            visible={inputFileOpen}
            onCancel={() => toggleFileModal(false)}
            requestId={id}
            section={selectedItem}
            subSection={onSelectedMenuItem}
          />
          <Button onClick={handleSave} disabled={!saveBtnFlags?.[selectedItem]}>
            Save
          </Button>
        </div>
      </Header>
      <Content className="right-content">{children}</Content>
    </Layout>
  );
};

const mapStateToProps = (state: TRootState) => ({
  downloadDetails: state.fetchDownloadGeneratedFile,
  saveBtnFlags: state.saveBtnFlagReducer.saveBtnFlag,
  isFileDownloaded: state.booleanReducer.isBooleanVariable,
  processSimplicationData: state.processSimplification.existingData?.data,
  processSimplEditedData: state.processSimplification.editedData,
  timelineSelectedValues: state.projectPlan.selectedTimelineValues,
  timelineData: state.projectPlan.timeline,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsDownloaded: (flag: boolean) => dispatch(setBooleanVariable(flag)),
  fetchSubsectionForKDD: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchKDDSubSection(payload)),
  fetchSubsectionForMaturity: (payload: TFetchSubSectionPayload) =>
    dispatch(fetchProcessMaturitySubSection(payload)),
  fetchDataForDownloadingFile: (reqId: string) => dispatch(fetchDownloadRequest(reqId)),
  updateSaveBtnFlag: (section: string, flag: boolean) => dispatch(updateSaveValue(section, flag)),
  saveProjectTimeline: (payload: TSaveProjectTimelinePayload) =>
    dispatch(saveTimelineReport(payload)),
  saveEditedContent: (reqId: number, selectedItem: string, payload: SaveApiPayload[]) =>
    dispatch(putEditedContentToSaveRequest(reqId, selectedItem, payload)),
  getProcessSimpDetails: (payload: TProcessSimpDetailsPayload) =>
    dispatch(fetchProcessSimplificationDetails(payload)),
  saveKDDData: (payload: TKDDSavePayload) => dispatch(saveKDDRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
